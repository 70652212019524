@tailwind base;
@tailwind components;
@tailwind utilities;
/* @font-face {
  font-family: "FrutigerCE-Roman";
  src: url("fonts/FrutigerCE-Roman.eot");
  src: url("fonts/FrutigerCE-Roman.eot?#iefix") format("embedded-opentype"), url("fonts/FrutigerCE-Roman.woff") format("woff"), url("fonts/FrutigerCE-Roman.ttf") format("truetype"), url("fonts/FrutigerCE-Roman.svg") format("svg");
  font-weight: normal;
  font-style: normal;
} */
/* @font-face {
  font-family: "FrutigerCE4";
  src: url("fonts/FrutigerCE-Bold.eot");
  src: url("fonts/FrutigerCE-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/FrutigerCE-Bold.woff") format("woff"), url("fonts/FrutigerCE-Bold.ttf") format("truetype"), url("fonts/FrutigerCE-Bold.svg") format("svg");
  font-weight: bold;
  font-style: normal;
} */

/* TODO: 494 errors potentially fixable with the "--fix" option. npx stylelint "src/ * * / *.css"  - had to add spaces to fit in comment */
@font-face {
  font-family: "FrutigerCE-Roman";
  src: url("fonts/Frutiger_roman.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FrutigerCE4";
  src: url("fonts/Frutiger_bold.ttf");
  font-weight: bold;
  font-style: normal;
}
/* @font-face {
  font-family: "FrutigerLTStd-Light";
  src: url("fonts/Frutiger_light.otf");
  font-weight: bold;
  font-style: normal;
} */
@font-face {
  font-family: "FrutigerLT2";
  src: url("fonts/FrutigerLT-Bold.eot");
  src: url("fonts/FrutigerLT-Bold.eot?#iefix") format("embedded-opentype"),
    url("fonts/FrutigerLT-Bold.woff") format("woff"),
    url("fonts/FrutigerLT-Bold.ttf") format("truetype"),
    url("fonts/FrutigerLT-Bold.svg") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "FrutigerLTStd-Light";
  src: url("fonts/frutigerltstd-light.eot");
  src: url("fonts/frutigerltstd-light.eot?#iefix") format("embedded-opentype"),
    url("fonts/frutigerltstd-light.woff") format("woff"),
    url("fonts/frutigerltstd-light.ttf") format("truetype"),
    url("fonts/frutigerltstd-light.svg#frutigerltstd-light") format("svg");
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #212529;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 !important;
  overflow: visible !important;
}
body,
input::placeholder,
.MuiFormLabel-root {
  font-size: 14px !important;
}
button,
input,
optgroup,
select,
textarea {
  font-size: 14px !important;
}
.font-frutiger-ce4 {
  font-family: "FrutigerCE4";
}
.font-frutiger-light {
  font-family: "FrutigerLTStd-Light";
}
.font-frutiger-roman {
  font-family: "FrutigerCE-Roman";
}
.font-georgia {
  font-family: Georgia, "Times New Roman", Times, serif;
}

@layer components {
  .post-content a {
    border-bottom: 1px solid transparent;
    color: #33a1e7;
    text-decoration: none;
    background-color: transparent;
  }
  .post-content a:not(.no-border):focus,
  .post-content a:not(.no-border):hover {
    border-color: #33a1e7;
    color: #33a1e7;
  }
  .post-content .action-titles {
    @apply px-4 py-2 font-semibold text-white bg-blue-500 rounded-lg shadow-md shadow-stone-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  }
  .post-content .list-group li {
    @apply text-center;
  }
  .post-content .alignleft:not("blue-bg-wrap") {
    @apply float-left ml-0;
  }
  .post-content .alignright:not("blue-bg-wrap") {
    @apply float-right mr-0;
  }
  .post-content p,
  .entry-content p,
  .post-content ul,
  .entry-content ul {
    @apply !mb-5 w-full leading-5 xl:leading-7;
  }
  .post-content:not(.text-left) p,
  .entry-content:not(.text-left) p,
  .post-content:not(.text-left) ul,
  .entry-content:not(.text-left) ul {
    @apply text-justify;
  }
  /* .post-content p img:not(.no-wrap) {
    @apply bg-[#008ae1] mx-8 mt-2 mb-4 rounded-md;
  } */
  .post-content p .tight {
    @apply leading-3;
  }
  .post-content p {
    @apply leading-6;
  }
  .post-content p img:not(.no-wrap) {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 5px -3px var(--tw-shadow-color),
      0 3px 3px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-shadow-color: #292524;
    --tw-shadow: var(--tw-shadow-colored);
    border: 4px solid #008ae1;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
  }
  .post-content p strong {
    font-size: 1.1rem;
  }
  article p {
    text-align: justify;
  }
  article p img,
  article ul li img {
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    position: relative;
  }
  .arrow-link i {
    color: #0189e0;
  }
  .background-blue {
    @apply bg-[#008ae1] text-white text-sm p-4;
  }
  .rounded-r-none + div,
  .rounded-r-none + div div {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .post-content h4 {
    @apply text-[14px] lg:text-[16px] xl:text-[18px];
  }
  .post-content iframe {
    @apply border-2 border-[#008ae1] border-b-[4px] m-auto rounded-md;
  }
  .post-content iframe {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 5px -3px var(--tw-shadow-color),
      0 3px 3px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-shadow-color: #292524;
    --tw-shadow: var(--tw-shadow-colored);
    max-width: 100%;
  }
  .icon-hover-white:hover svg {
    stroke: #ffffff;
  }
  #interviewing-locations .add-deadline,
  #interviewing-locations .add-apply-location {
    display: none;
  }
  .fade-edge-left {
    @apply min-[1979px]:before:z-10 min-[1979px]:before:w-full min-[1979px]:before:h-full min-[1979px]:before:absolute  min-[1979px]:before:bg-gradient-to-r  min-[1979px]:before:from-[#fcfcfc] min-[1979px]:before:from-0% min-[1979px]:before:via-transparent min-[1979px]:before:via-15%;
  }
  .fade-edge-right {
    @apply min-[1979px]:before:z-10 min-[1979px]:before:w-full min-[1979px]:before:h-full min-[1979px]:before:absolute  min-[1979px]:before:bg-gradient-to-l  min-[1979px]:before:from-[#fcfcfc] min-[1979px]:before:from-0% min-[1979px]:before:via-transparent min-[1979px]:before:via-15%;
  }
  .divider-line li:not(:last-child) {
    @apply border-r border-[#a2a2a2];
  }
  .interview-locations ul {
    @apply text-left;
  }
  .interview-locations li {
    @apply w-1/3;
  }
  /* .interview-locations ul li:last-child {
    @apply text-right;
  } */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
article h2 {
  font-weight: 700;
  transition: all 0.5s ease;
}
/* .widget-container ul li .drop-item-content {
  font-family: "FrutigerCE4";
  line-height: 1.2;
} */
.widget-container a:not(.btn) {
  color: #191919;
  font-family: "FrutigerCE4";
  line-height: 1.2;
}
.widget-title {
  font-family: "FrutigerCE-Roman";
}
.hidden .hidden-caption {
  opacity: 0;
  margin-right: -15rem;
}
.hidden.\!block .hidden-caption {
  opacity: 100;
  margin-right: 0;
}

.carousel-item .caption-wrapper {
  padding: 4px 8px;
  background-color: rgba(0, 138, 225, 0.8);
  font-family: "FrutigerCE-Roman";
  color: white;
  opacity: 0;
  -webkit-transition: 0.25s all ease-in-out 0.1s;
  -moz-transition: 0.25s all ease-in-out 0.1s;
  -ms-transition: 0.25s all ease-in-out 0.1s;
  -o-transition: 0.25s all ease-in-out 0.1s;
  transition: 0.25s all ease-in-out 0.1s;
  margin-left: -60px;
  margin-top: -10px;
  height: auto;
  margin-bottom: 0;
  border-radius: 4px;
}
.carousel-item .caption-wrapper .inner-caption h1 {
  margin-bottom: 0;
  margin-right: 4px;
  margin-left: 4px;
}

#menu-footer li a,
footer .widget-title {
  font-family: "FrutigerCE-Roman";
  text-transform: uppercase;
  color: #3b3b3b;
  padding: 0;
  line-height: 18px;
}
footer .social-title {
  font-family: "FrutigerCE-Roman";
  text-transform: uppercase;
  color: #3b3b3b;
  padding: 0;
  line-height: 18px;
}
.fade-in-text {
  animation: fadeIn 0.75s;
}
.fade-out-text {
  animation: fadeOut 0.25s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.trans-ease {
  -webkit-transition: 200ms ease;
  -moz-transition: 200ms ease;
  -o-transition: 200ms ease;
  transition: 200ms ease;
}
.trans-ease-all {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.trans-ease-slow {
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.hideme {
  opacity: 0;
  visibility: hidden;
}
.animated {
  opacity: 1 !important;
  visibility: visible !important;
  transition: opacity 0.3s ease-in-out;
}
.animated-fast {
  transition: opacity 0.1s ease-in-out;
}
/* #side-content {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease-in-out; 
}

#side-content.animated {
  opacity: 1;
  transform: translateY(0); 
} */

.custom-shadow {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 5px -3px var(--tw-shadow-color),
    0 3px 3px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: #292524;
  --tw-shadow: var(--tw-shadow-colored);
}
.drop-item {
  @apply flex flex-wrap items-center w-full text-xs select-none sm:w-96 sm:text-sm gap-x-3;
}
.drop-item-content {
  @apply invisible h-0 mt-0 overflow-hidden transition-all select-text peer-checked:visible peer-checked:my-1 peer-checked:h-full peer-checked:basis-full;
}
.text-shadow:hover,
.text-shadow:focus,
.text-shadow:active,
.text-shadow.active {
  text-shadow: 1px 1px 14px #97d8fa, 1px 1px 1px #28a1ec;
  border-color: white;
}
.white-title {
  color: #fff;
  text-shadow: 1px 1px 0 #008de2, 0px -1px 0 #4564cc;
}
.black-title {
  color: #333;
  text-shadow: 1px 1px 0 #9ca1ad, 0px 0 0 #111113;
}
.search-wrap .group {
  display: none !important;
  outline: none !important;
  border: 0 !important;
}
.btn {
  background: radial-gradient(#00c0ec, #008de1) !important;
  background-image: radial-gradient(#00c0ec, #008de1) !important;
  background-color: transparent !important;
  background-repeat: no-repeat;
  color: white;
  /* border: 1px solid #e3e5e9 !important; */
  padding: 0.5rem 0.75rem;
}

.btn-blue:disabled {
  opacity: 0.5;
}
.btn-blue:hover {
  background: radial-gradient(#00c0ec, #0398ee) !important;
  background-image: radial-gradient(#00c0ec, #0398ee) !important;
}
.btn.btn-grey {
  background: radial-gradient(#eaebee, #c9cbd0) !important;
  background-image: radial-gradient(#eaebee, #c9cbd0) !important;
  background-repeat: no-repeat;
  color: black;
  border: 1px solid #008de2;
  padding: 0.5rem 0.75rem;
}
.btn-grey:hover {
  background: radial-gradient(#f0f1f5, #d2d3d4) !important;
  background-image: radial-gradient(#f0f1f5, #d2d3d4) !important;
}
.bold {
  font-weight: 700;
}
.blue,
.has-vivid-cyan-blue-color,
.post-content p a,
a.blue:hover {
  color: #33a1e7;
}
a.blue:hover {
  border-color: rgba(50, 161, 231, 0.75) !important;
}
.blue-title,
.post-content p a:hover {
  color: #0189e0;
}
/* .post-content p a:hover {
  text-decoration: underline;
} */
.blue-strong,
.blue-strong * {
  font-weight: 700 !important;
  color: #0189e0 !important;
}
ul.blue-triangles {
  list-style: none; /* Remove default bullets */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

ul.blue-triangles li {
  width: 100%; /* Make the <li> fill the full width of its container */
  box-sizing: border-box; /* Include padding/border in width calculations */
}

ul.blue-triangles li:before {
  content: "";
  display: inline-block;
  border-color: transparent rgb(0, 157, 225);
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.4em;
  width: 0;
  height: 0;
  transform: rotate(0deg);
  flex-shrink: 0;
  padding-left: 8px;
}
/* .blue-triangles ul li:before {
  content: "";
  display: inline-block;
  border-color: transparent rgb(0, 157, 225);
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.4em;
  width: 0;
  height: 0;
  transform: rotate(0deg); 
  flex-shrink: 0; 
  padding-left: 8px;
}
.blue-triangles li {
  position: relative;
  padding-left: 1.5em; 
}

.blue-triangles li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  border-color: transparent #009de1;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.4em;
  transition: transform 0.3s ease;
}

.blue-triangles li[aria-expanded="true"]::before {
  transform: translateY(-50%) rotate(90deg); 
} */

.blue-bg-wrap .blug-bg {
  opacity: 0;
}
.blue-bg-wrap,
.no-blue-bg-wrap {
  max-width: 300px;
  display: flex;
  margin: 1em auto;
  opacity: 0;
}
.blue-bg-wrap.alignright {
  margin: 0.5em 0 0.5em 1.5em;
}
.blue-bg-wrap.alignleft {
  margin: 0.5em 1.5em 0.5em 0;
}
.blue-bg-wrap.alignleft.floatleft,
.blue-bg-wrap.alignright.floatright {
  margin: 1em;
}
.entry-content p img {
  max-width: 298px;
}
/* article div.blue-bg {
border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  background: rgba(0, 138, 225, 1);
  color: rgba(0, 138, 225, 1);
  padding: 1px 1px 4px 1px;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px black;
  max-width: fit-content;
  margin: 0 auto 1rem;
} */
article span img.alignright:not(.map-location-image):not(.no-blue),
article span img.alignleft:not(.map-location-image):not(.no-blue) {
  opacity: 0;
}
article span.blue-bg img {
  margin: 0;
  float: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  background: rgba(0, 138, 225, 1);
}
article span.no-blue-bg {
  background: none;
  margin: auto;
  text-align: center;
}
article h4,
article h3 {
  transition: all 0.5s ease;
  font-weight: 700;
}
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}
.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.aligncenter img {
  margin: auto;
}
a img.alignright {
  float: right;
  margin: 0.313rem 0 1.25rem 1.25rem;
}
a img.alignnone {
  margin: 0.313rem 1.25rem 1.25rem 0;
}
a img.alignleft {
  float: left;
  margin: 0.175rem 0.25rem 0.25rem 0;
}
a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.wp-caption.alignnone {
  margin: 0.313rem 1.25rem 1.25rem 0;
}
.wp-caption.alignleft {
  margin: 0.313rem 1.25rem 1.25rem 0;
}
.wp-caption.alignright {
  margin: 0.313rem 0 1.25rem 1.25rem;
}
.alignright {
  float: right;
}
.alignleft {
  float: left;
}
.float-images-left img {
  float: left;
  margin-right: 1rem;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}
.card.small .blue-triangles ul li:before {
  border-color: transparent rgb(6, 105, 148);
  left: -1em;
  top: 1.2em;
}
.blue-arrows ul,
ul.blue-arrows {
  margin: 0.75em;
  padding: 0 0.75em;
  list-style: none;
  transition: all 0.5s ease;
}
.blue-arrows li {
  position: relative;
  padding-bottom: 8px;
  line-height: 1.6;
  text-align: left;
}
.blue-arrows li div,
.blue-arrows li p {
  line-height: 1.6;
}
.blue-arrows2 li:before {
  /* top: 8px;
  left: -12px; */
  left: -6px;
  content: "";
  display: inline-flex;
  position: relative;
  border-right: 3px solid rgb(0, 157, 225);
  border-bottom: 3px solid rgb(0, 157, 225);
  width: 8px;
  height: 8px;
  transform: translateY(-50%) rotate(-45deg);
  border-radius: 25%;
  transition: all 0.5s ease;
}
.blue-arrows li:before {
  top: 10px;
  left: -14px;
  content: "";
  position: absolute;
  border-right: 3px solid rgb(0, 157, 225);
  border-bottom: 3px solid rgb(0, 157, 225);
  width: 8px;
  height: 8px;
  transform: translateY(-50%) rotate(-45deg);
  border-radius: 25%;
}
.blue-arrows.large-blue li:before {
  top: 10px;
}

.blue-arrows li p {
  display: inline;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
  background-image: none;
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
#home-slideshow,
header .banner {
  max-width: 1996px;
}

.side-map-wrap {
  background: url("images/grid-blue.png") 50% 50% / 25px;
}
.home-map-wrap {
  max-width: 400px;
}
/* #home-messages-wrap #messages-from-staff {
  background: url("images/small-map-grey.png") 68px 84px / 264px no-repeat;
} */
.divider-dots-sidebar {
  background: url("images/divider-dots-left.png") left top repeat-x;
}
.divider-dots,
.interview-locations ul:not(:last-child) {
  background: url("images/divider-dots-left.png") left bottom repeat-x;
}
.side-map-wrap .side-map-marker-big {
  background: url("images/side-map-marker-med.png") no-repeat;
}
div.submit-application-side {
  background: url("images/submit-application-side-grey.png") center center
    no-repeat;
}
#side-messages-wrap .staff-image {
  background: url("images/side-messages-bg.png") center center no-repeat;
  background-size: 79px;
}
#home-messages-wrap .staff-image {
  background: url("images/home-messages-bg.png") center center no-repeat;
  background-size: 96px;
}
#careers .staff-image {
  position: relative !important;
  float: left;
  margin-top: 0 !important;
  background: url("images/careers-image-bg.png") no-repeat;
  width: 140px;
  height: 120px;
  /* display: none; */
}
#careers .staff-image img {
  margin-top: 5px !important;
}
.map-location-thumb {
  opacity: 0;
}
.map-location-title {
  bottom: 0;
  left: 2px;
  top: 57px;
  width: 86px;
  height: 21px;
  background: #0000007a;
  font-size: 12px;
  padding-top: 2px;
  opacity: 0;
}
.map-thumb-wrap.animated .map-location-thumb,
.map-thumb-wrap.animated .map-location-title {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
#map-messages-thumbnail,
#testimonials-from-our-staff .staff-image img {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 6px;
  border: 2px solid #fff;
  background: transparent;
  z-index: 1;
  height: auto;
}

#career-opportunities-blue {
  width: 274px;
  height: 38px;
  margin: auto;
  padding: 13px 0 0 32px;
  background: transparent url(images/career-opportunities-bg2.png) no-repeat;
}

#career-opportunities-blue .hover-arrows {
  color: #33a1e7;
  font-weight: 700;
  font-size: 0.9rem;
  padding: 0.5rem 0;
}
#testimonials-from-our-staff .staff-image {
  width: 120px;
  height: 130px;
  background: url("images/map-thumbnail-bg.png") no-repeat;
  overflow: hidden;
  z-index: 0;
}
#testimonials-from-our-staff .staff-image img {
  margin-top: 14px;
  width: 111px;
  height: 111px;
  background: #fff;
}
#map-world-wrapper {
  background: url("images/map-world-white.png") 50% 0 no-repeat fixed;
}
.background-blue-gradient {
  background: -webkit-radial-gradient(
    75% 50%,
    rgb(52, 188, 247),
    #0da6e8,
    #0271b6
  );
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border: 1px solid green;
  background: white;
  -webkit-box-shadow: 0 0 0px 1000px #fcfcfc inset;
  transition: background-color 5000s ease-in-out 0s;
  border: none;
  font-size: 1rem !important;
}
input:-webkit-autofill + label {
  --tw-text-opacity: 1;
  -webkit-box-shadow: 0 0 0px 1000px #fcfcfc inset !important;
  z-index: 1000;
  color: rgb(59 113 202 / var(--tw-text-opacity));
  --tw-scale-x: 0.8;
  --tw-scale-y: 0.8;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: -1.15rem;
  transform-origin: 0 0;
} */

.online-form input.required + label::after,
.online-form label.required::after {
  content: " *";
  display: contents;
  color: #33a1e7;
  width: 1.5em;
}
.side-blue {
  background: #008ae1;
  background: radial-gradient(#00c1ec, #008ae1);
  background-repeat: no-repeat;
  max-width: 366px;
  margin: 1rem auto;
}
.side-grey {
  background: #c9cbd0;
  background: radial-gradient(#eaebee, #c9cbd0);
  background-repeat: no-repeat;
  max-width: 366px;
  margin: 1rem auto;
}
div.submit-application-side {
  height: 44px;
  padding: 14px 0 0 36px;
  text-align: left;
  text-indent: -999px;
}
.side-wrap .animated a.side-submit-application {
  margin: 87px auto 0 auto;
}

.parent-nav .blue-arrow {
  content: "";
  border-right: 3px solid rgb(0, 157, 225);
  border-top: 3px solid rgb(0, 157, 225);
  width: 9px;
  height: 9px;
  right: 0;
  outline: 0;
  transform: translateY(-50%) rotate(-45deg);
  border-radius: 25%;
  cursor: pointer;
  position: absolute;
  top: 12px;
  transition: all 0.5s ease;
}
.side-map-wrap .side-map-marker-big {
  width: 12px;
  height: 20px;
  display: block;
  z-index: 2;
}
/* .side-map-wrap .side-map-marker-big-wrapper {
  width: 242px;
  height: 182px;
  top: 100px;
} */
#side-view-all-locations {
  background: url("images/side-view-all-locations-bg.png") no-repeat;
  padding-left: 22px;
  height: 26px;
  line-height: 26px;
}
#side-messages-from-staff {
  background: url("images/side-messages-from-staff-bg.png") no-repeat;
  padding-left: 34px;
  height: 26px;
  line-height: 26px;
  transition: 0.5s;
}
.worldwide-bg .row > div,
.main-bg .row > div {
  display: flex;
  height: 34px;
  border-left: 2px solid #f2f5f9;
  margin: 4px 0;
}
.worldwide-bg .row > div:first-child,
.main-bg .row > div:first-child {
  border: 0;
}
.worldwide-bg > div,
.main-bg > div {
  background-color: #309ee5;
  padding: 0.2em;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.worldwide-bg > div i,
.main-bg > div i {
  font-size: 20px;
  line-height: 26px;
}
.recruiting-table .heading {
  color: #fff;
  letter-spacing: 0.02em;
  font-weight: 700;
  font-size: 10px;
}

/*Testimonials Pages Only*/
body.gradient-back {
  background: -webkit-radial-gradient(
    75% 50%,
    rgb(52, 188, 247),
    #0da6e8,
    #0271b6
  );
}
.map-location {
  width: 90px;
  z-index: 498;
  height: 80px;
  position: absolute;
  margin-left: -35px;
  margin-top: -68px;
  cursor: pointer;
  overflow: hidden;
}
.map-location.animated {
  opacity: 1 !important;
  visibility: visible !important;
  transition: opacity 0.5s ease-in-out;
}
.map-marker-small.hidden {
  opacity: 0;
  visibility: hidden;
  display: none;
}
.map-location-thumbnail,
.map-location-name {
  display: none;
  z-index: 1001;
}
.map-marker {
  width: 20px;
  height: 22px;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  display: none;
}
.map-marker.animated {
  display: block;
}
#map-messages {
  color: #ffffff;
  margin-bottom: 10px;
  padding: 0 10px;
  font-size: 14px;
  font-family: Georgia, "Times New Roman", Times, serif;
  line-height: 19px;
}
.map-marker-small {
  width: 20px;
  z-index: 1;
  height: 22px;
  background: url("images/map-markers.png") center -8px no-repeat;
}
.map-marker-big {
  width: 43px;
  height: 63px;
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  margin-top: -40px;
  margin-left: -12px;
  background: url("images/map-marker-big.png") no-repeat;
}
.map-location-spacer {
  height: 55px;
  z-index: 1;
  width: 87px;
  background: transparent;
  display: none !important;
}
.location-messages .staff-message,
#map-messages {
  letter-spacing: 0.025em;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.message-block .info-wrap {
  width: calc(100% - 135px);
  float: left;
}
.staff-location {
  letter-spacing: 0.05em;
  font-weight: bold;
  font-size: 16px;
}
#career-opportunities-white {
  z-index: 999;
  width: 274px;
  height: 38px;
  padding: 13px 0 0 32px;
  background: transparent url("images/career-opportunities-bg.png") no-repeat;
}
.staff-video .video-wrapper {
  border: 1px solid #19538f;
  background: rgba(0, 138, 225, 1);
  margin: 0;
  padding: 6px 5px 6px;
}
.wp-video {
  width: 100% !important;
  height: auto;
}
.staff-video .video-wrapper {
  width: 412px !important;
  max-width: 412px !important;
  min-width: 412px !important;
  height: 313px !important;
  max-height: 313px !important;
  min-height: 313px !important;
  cursor: pointer;
}
.staff-video .wp-video .mejs-container:not(.mejs-container-fullscreen) {
  width: 400px !important;
  max-width: 400px !important;
  min-width: 400px !important;
  height: 300px !important;
  max-height: 300px !important;
  min-height: 300px !important;
  cursor: pointer;
}
.staff-video .video-wrapper * {
  max-width: 100% !important;
  max-height: 100% !important;
}
/* .video-wrapper video {
  display: none;
} */
.mejs-controls .mejs-button BUTTON:focus {
  outline: solid 0px #006699 !important;
}
.mejs-container,
.mejs-embed,
.mejs-embed body,
.mejs-container .mejs-controls,
.mejs-controls {
  background: transparent;
  background-color: transparent !important;
}
.staff-video .video-wrapper {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
.video-wrapper .video-inner {
  width: 400px !important;
  max-width: 400px !important;
  min-width: 400px !important;
  height: 300px !important;
  max-height: 300px !important;
  min-height: 300px !important;
  background: #000;
}
.mejs-overlay {
  width: 400px !important;
  height: 300px !important;
}
.mejs-controls DIV.mejs-time-rail,
.mejs-controls .mejs-time-rail A {
  width: 100% !important;
  max-width: 388px !important;
  height: 19px;
  margin: 0;
  padding-top: 0;
}
.location-messages.animated .video-wrapper {
  display: block;
}
.post-content .map_block {
  display: none;
}
.map_block {
  /* padding-bottom: 4px; */
  margin: 1rem 0 1.5rem;
  overflow: hidden;
  width: 100%;
  height: 366px;
  position: relative;
  z-index: 1000;
  border: 4px solid transparent;
  border-top-width: 2px;
  border-left-width: 2px;
  border-right-width: 2px;
  border-radius: 0.375rem;
}
.map_block.animated {
  border: 4px solid rgb(0 138 225 / var(--tw-bg-opacity));
  border-top-width: 2px;
  border-left-width: 2px;
  border-right-width: 2px;
  --tw-bg-opacity: 1;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 5px -3px var(--tw-shadow-color),
    0 3px 3px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: #292524;
  --tw-shadow: var(--tw-shadow-colored);
}
#map_canvas {
  width: 100%;
  height: 360px;
  z-index: 99999;
  opacity: 0;
  visiblity: hidden;
}
.loading {
  position: absolute;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0,0,0,0.3); */
}
/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 138, 225, 0.75) 1.5em 0 0 0,
    rgba(0, 138, 225, 0.75) 1.1em 1.1em 0 0, rgba(0, 138, 225, 0.75) 0 1.5em 0 0,
    rgba(0, 138, 225, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 138, 225, 0.75) 0 -1.5em 0 0,
    rgba(0, 138, 225, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 138, 225, 0.75) 1.5em 0 0 0,
    rgba(0, 138, 225, 0.75) 1.1em 1.1em 0 0, rgba(0, 138, 225, 0.75) 0 1.5em 0 0,
    rgba(0, 138, 225, 0.75) -1.1em 1.1em 0 0,
    rgba(0, 138, 225, 0.75) -1.5em 0 0 0,
    rgba(0, 138, 225, 0.75) -1.1em -1.1em 0 0,
    rgba(0, 138, 225, 0.75) 0 -1.5em 0 0,
    rgba(0, 138, 225, 0.75) 1.1em -1.1em 0 0;
}
/* Animation */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.text-center {
  text-align: center;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}
.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 0.313rem;
  line-height: normal;
  padding: 0.938rem 1.438rem 0.875rem;
  text-decoration: none;
  top: 0.313rem;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}
/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

/* Home Page Only */

.home-why-aeon {
  background-image: url("images/about-us-title-bg.png");
  background-repeat: no-repeat;
}
.home-careers {
  background-image: url("images/recruiting-title-bg.png");
  background-repeat: no-repeat;
}
.home-from-our-staff {
  background-image: url("images/from-our-staff-title-bg.png");
  background-repeat: no-repeat;
}
.location-messages .image-wrap {
  margin-left: 5px;
}
.side-map-wrap .side-map-map {
  background: url("images/map-japan-side-widget-green.png") 2px 101px / 264px
    no-repeat;
}
.home-map-wrap .side-map-map {
  background: url("images/small-map-grey.png") 2px 78px / 264px no-repeat;
}
.side-map-marker-big {
  background: url("images/map-marker-med-blue.png") no-repeat;
  width: 12px;
  height: 20px;
  display: block;
  z-index: 2;
}
.styled-clear span.absolute.right-9 {
  border: 1px solid rgba(0, 0, 0, 0.175);
  background: rgba(0, 0, 0, 0.075);
  border-radius: 4px;
}
.styled-clear span.absolute.right-9:hover {
  color: rgb(51, 161, 231);
}
.input-wrap .MuiFormLabel-asterisk {
  color: #33a1e7;
  margin-left: 1px;
}
input-wrap .MuiInputLabel-root[data-shrink="false"] .MuiFormLabel-asterisk {
  font-size: 14px;
}
.input-wrap .MuiInputLabel-root[data-shrink="false"] {
  -webkit-transform: translate(12px, 5px) scale(1);
  -moz-transform: translate(12px, 5px) scale(1);
  -ms-transform: translate(12px, 5px) scale(1);
  transform: translate(12px, 5px) scale(1);
  margin-right: 14px;
}
.input-wrap .MuiInputLabel-shrink {
  --tw-scale-x: 0.8;
  --tw-scale-y: 0.8;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: -0.65rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform-origin: 0 0;
  left: 0.75rem;
}
.input-wrap.required .MuiInputLabel-root::after {
  content: " *";
  color: #e80000;
  display: inline-flex;
  padding: 0 4px 0 4px;
  position: relative;
  background: #fff;
  font-size: 14px;
  top: -2px;
}
.input-wrap.required .MuiInputLabel-root[data-shrink="true"]::after {
  font-size: 12px;
}
.input-wrap
  .MuiOutlinedInput-root:not(:focus):hover
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23);
}
.input-wrap
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #1976d2 !important;
}
.letter-box {
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  box-shadow: 10px 10px 5px #ccc;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 40px;
  float: left;
  border: 1px solid #ccc;
  background: #ffffff;
}
/* ----------------- stuff like this needs split off, this is recruiting locations and corporate ----------- */
.hover-wrap {
  background: url("images/divider-dots-main.png") left bottom repeat-x;
  cursor: pointer;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.hover-wrap ul {
  padding-left: 0;
  list-style: none;
  display: flex;
  width: 100%;
  margin: 0;
  letter-spacing: 0.02em;
  font-size: 0.9rem;
}

.hover-wrap ul li {
  width: 100%;
  display: inline-block;
  padding-left: 0.5em;
}

.hover-wrap .click-to-apply {
  width: 100%;
  text-align: center;
  font-size: 12px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  background: transparent;
  font-weight: 700;

  border-bottom: 18px solid #008de1;
  opacity: 0;
  visibility: hidden;
  height: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.hover-wrap .click-to-apply {
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.hover-wrap:hover .click-to-apply {
  opacity: 1;
  visibility: visible;
  height: 60px;
  box-shadow: 0 8px 6px -6px black;
}
.hover-wrap:hover .click-to-apply a {
  position: absolute;
  left: 0;
  right: 0;
}
#main-office-block .hover-wrap ul li:nth-child(1),
#tokyo-office-block .hover-wrap ul li:nth-child(1) {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
#main-office-block .hover-wrap ul li:nth-child(2),
#tokyo-office-block .hover-wrap ul li:nth-child(2) {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
#main-office-block .hover-wrap ul li:nth-child(3),
#tokyo-office-block .hover-wrap ul li:nth-child(3) {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
#worldwide-block li.add-apply-location {
  display: none;
}
#worldwide-block .hover-wrap ul li {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
#worldwide-block .hover-wrap ul li {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  vertical-align: middle;
}
.hover-wrap:hover {
  color: #fff !important;
  background: #309ee5 url(images/divider-dots-main.png) left top repeat-x;
  border-top: 1px solid white;
}

.no-border {
  background: none;
  margin-top: 1px;
}

@media only screen and (max-width: 767px) and (min-width: 480px) {
  .message-block .info-wrap {
    width: calc(100% - 95px);
  }
}

@media only screen and (max-width: 767px) and (min-width: 480px) {
  .mejs-controls DIV.mejs-time-rail,
  .mejs-controls .mejs-time-rail A {
    width: 100% !important;
    max-width: 320px !important;
  }
}
@media only screen and (max-width: 479px) {
  html {
    font-size: 75%;
  }
  .staff-video .video-wrapper {
    width: 232px !important;
    max-width: 232px !important;
    min-width: 232px !important;
    height: 177px !important;
    max-height: 177px !important;
    min-height: 177px !important;
  }
  .video-wrapper .video-inner,
  .staff-video .wp-video .mejs-container:not(.mejs-container-fullscreen) {
    width: 220px !important;
    max-width: 220px !important;
    min-width: 220px !important;
    height: 165px !important;
    max-height: 165px !important;
    min-height: 165px !important;
  }
}
@media only screen and (max-width: 767px) {
  html {
    font-size: 80%;
  }
  article h2,
  article h3,
  article h4 {
    font-size: 14px;
  }
  .alignright:not(.floatright),
  .alignleft:not(.floatleft) {
    float: none;
    margin: 1.5em auto 1.5em;
    display: block;
  }
  #map-wrapper {
    background: transparent !important;
  }
  .map-marker {
    display: none;
  }
  .staff-video .video-wrapper {
    width: 352px !important;
    max-width: 352px !important;
    min-width: 352px !important;
    height: 268px !important;
    max-height: 268px !important;
    min-height: 268px !important;
  }
  .video-wrapper .video-inner,
  .staff-video .wp-video .mejs-container:not(.mejs-container-fullscreen) {
    width: 340px !important;
    max-width: 340px !important;
    min-width: 340px !important;
    height: 255px !important;
    max-height: 255px !important;
    min-height: 255px !important;
  }
}

@media only screen and (max-width: 1023px) {
  .worldwide-bg .heading i,
  .main-bg .heading i {
    display: none !important;
  }
}

article h1 {
  font-size: 22px;
}
article h2 {
  font-size: 15px;
}
article h3 {
  font-size: 14px;
}
article h4 {
  font-size: 13px;
}
@media only screen and (min-width: 767px) {
  article h1 {
    font-size: 30px;
  }
  article h2 {
    font-size: 17px;
  }
  article h3 {
    font-size: 16px;
  }
  article h4 {
    font-size: 15px;
  }

  .blue-bg-wrap,
  .no-blue-bg-wrap {
    max-width: 230px;
    margin: 1em;
  }
  .search-wrapper > div,
  .search-wrapper > div > div {
    height: 38px;
  }
}

@media only screen and (min-width: 1024px) {
  #map-messages-block {
    background: url("https://www.aeonet.com/wp-content/themes/wp-bootstrap-child/inc/assets/img/map-japan-greena3.png")
      50% 0 no-repeat;
  }
  .worldwide-bg .row > div,
  .main-bg .row > div {
    display: flex;
    align-items: center;
    height: 48px;
  }
  article h2 {
    font-size: 18px;
  }
  article h3 {
    font-size: 17px;
  }
  article h4 {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1200px) {
  .blue-bg-wrap,
  .no-blue-bg-wrap {
    max-width: 332px;
  }
  .hover-wrap ul {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1280px) {
  article h2 {
    font-size: 20px;
  }
  article h3 {
    font-size: 19px;
  }
  article h4 {
    font-size: 18px;
  }
}
@media only screen and (min-width: 768px) {
  .blue-arrows .large-blue li:before {
    top: 12px;
  }
}
@media only screen and (min-width: 1366px) {
  body,
  input::placeholder,
  .MuiFormLabel-root {
    font-size: 16px !important;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-size: 16px !important;
  }
  .blue-arrows li:before {
    top: 12px;
    left: -16px;
  }
}

/* @media only screen and (min-width: 1600px) {
  .side-map-wrap .side-map-marker-big-wrapper {
    width: 278px;
    height: 208px;
    top: 79px;
    right: 0;
  }
} */
/* @media only screen and (min-width: 1846px) {
  .side-map-wrap .side-map-marker-big-wrapper {
    width: 278px;
    height: 208px;
    top: 79px;
    right: 0;
  }
} */

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.menu-item-animate {
  animation: slideIn 0.12s ease-out backwards;
}

nav {
  transition: height 0.3s ease;
}

ul {
  transition: height 0.3s ease;
}
body.mobile-menu-open {
  overflow: hidden; /* Prevent scrolling when the menu is open */
  position: relative;
}
body.mobile-menu-open .menu-container {
  height: fit-content;
  transition: height 0.55s ease-in;
}
#mobile-menu,
body.mobile-menu-open #side-nav-page,
body.mobile-menu-open #search-wrapper {
  transform: translateY(40px);
}

#mobile-menu .search-wrapper > div {
  width: 100%;
}
body.mobile-menu-open .banner-wrapper {
  transform: translateY(37px);
}
.menu-item-animate {
  background-color: #008de2e6;
}
.menu-item-animate:hover,
.menu-item-animate.active {
  background-color: #008ae1;
}
